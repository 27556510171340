import { ColDef, GridOptions, NewValueParams, ProcessDataFromClipboardParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DateHelper } from "./DateHelper";
import { cloneDeep, isEqual } from "lodash";
import React from "react";

const formatDate = (date: Date | undefined): string => {
	if (!date) {
		return "-";
	}
	return DateTime.fromJSDate(date).toLocaleString({ ...DateTime.DATE_FULL, month: "short", day: "2-digit" });
};

const formatPercentage = (value: number | undefined) => {
	if (value) {
		return Intl.NumberFormat(undefined, {
			style: "percent",
			minimumFractionDigits: 2,
			maximumFractionDigits: 4,
		}).format(value);
	}
	return "-";
};

type EditableGridColumnTypes = "editablePercentage" | "editableDate";

function getGridColumnTypes<TData>(
	onCellValueChange: (e: NewValueParams<TData>) => void,
): Record<EditableGridColumnTypes, ColDef<TData>> {
	const customTypesForEditableGrid: Record<EditableGridColumnTypes, ColDef> = {
		editablePercentage: {
			cellDataType: "number",
			valueFormatter: (a) => {
				return formatPercentage(a.value as number);
			},
			valueParser: (e) => {
				const newValue = parseFloat(e.newValue);
				if (!isNaN(newValue)) {
					return newValue / 100;
				}
				return null;
			},
			onCellValueChanged: onCellValueChange,
			editable: true,
		},
		editableDate: {
			cellDataType: "date",
			// cellEditor: 'agDateCellEditor',
			valueFormatter: (a) => {
				return formatDate(a.value as Date | undefined);
			},
			valueParser: (e) => {
				console.log({ newVal: e.newValue, e });
				return DateTime.fromISO(e.newValue).toJSDate();
			},
			onCellValueChanged: onCellValueChange,
			editable: true,
			cellClassRules: { "font-monospace": () => true },
		},
	};

	return customTypesForEditableGrid;
}

const preprocessDatesFromClipboard = (clipDataEvent: ProcessDataFromClipboardParams): string[][] | null => {
	console.log({ clipDataEvent });
	let result: string[][] | null;
	const rawCellsValue = clipDataEvent.data.flatMap((d) => d).filter((d) => (d ?? "").length > 0);
	// First try, we check if copied individual data can be converted to Dates. This can be mainly the case when doing a copy/paste operation from excel data
	const individualDatesValues = rawCellsValue.map(DateHelper.parseLongFrenchDate).filter((d) => d != null);
	if (individualDatesValues.length > rawCellsValue.length / 2) {
		result = individualDatesValues.map((v) => [DateTime.fromJSDate(v!).toISODate()!]);
	} else {
		// Next try, if we are doing a copy paste operation from the Term Sheet itself.
		// If we have selected multiple line in the PDF, the result is multiple line of one cell in the clipboard
		// We have to join them with a space, and check if we are good
		const rawStr = rawCellsValue.join(" ");
		result = DateHelper.parseDates(rawStr)
			.map((theParsedDate) => DateTime.fromJSDate(theParsedDate).toISODate() as string)
			.map((v) => [v]);
	}
	return result;
};

type EditableGridProps<TData> = {
	initialValue: TData[];
	rowIdAccessor: (data: TData) => string;
	coldef: GridOptions<TData>["columnDefs"];
	onChange: (data: TData[]) => void;
	onValueChangeDelay?: number;
	containerStyle?: Partial<CSSStyleDeclaration>;
	className?: string;
};

export function EditableGrid<TData>({
	initialValue,
	onChange,
	coldef,
	rowIdAccessor,
	onValueChangeDelay,
	containerStyle,
	className,
}: EditableGridProps<TData>) {
	const [localScheduleLines, setLocalScheduleLines] = useState<TData[]>([]);

	useEffect(() => {
		setLocalScheduleLines(cloneDeep(initialValue ?? []));
	}, [initialValue]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (!isEqual(localScheduleLines, initialValue)) {
				console.log("Local Schedule lines updated ! ", { localScheduleLines, lines: initialValue });
				onChange(localScheduleLines);
			}
		}, onValueChangeDelay ?? 1000);

		return () => clearTimeout(timeoutId);
	}, [localScheduleLines, initialValue, onChange, onValueChangeDelay]);

	const onCellValueChange = useCallback(
		(e: NewValueParams<TData>) => {
			const field = e.colDef.field!;
			const newScheduleLines = [...localScheduleLines];
			const slToUpdateIndex = newScheduleLines.findIndex((sl) => rowIdAccessor(sl) === rowIdAccessor(e.data));
			if (slToUpdateIndex > -1) {
				newScheduleLines[slToUpdateIndex] = { ...newScheduleLines[slToUpdateIndex], [field]: e.newValue };
				setLocalScheduleLines(newScheduleLines);
			}
		},
		[localScheduleLines, rowIdAccessor],
	);

	const gridCustomColumnTypes = useMemo(() => getGridColumnTypes<TData>(onCellValueChange), [onCellValueChange]);

	const gridOptions: GridOptions<TData> = {
		rowData: localScheduleLines,
		columnDefs: coldef,
		headerHeight: 32,
		rowHeight: 24,
		defaultColDef: { flex: 1, filter: true, resizable: true, editable: true },
		enableRangeSelection: true,
		suppressMultiRangeSelection: true,
		columnTypes: gridCustomColumnTypes,
		processDataFromClipboard: preprocessDatesFromClipboard,
		processCellFromClipboard: (e) => {
			return e.parseValue(e.value);
		},
		getRowId: (e) => rowIdAccessor(e.data),
	};

	return (
		<div className={`ag-theme-sg-bootstrap ag-theme-sg-bootstrap-condensed ${className}`}>
			<AgGridReact {...gridOptions} containerStyle={containerStyle} />
		</div>
	);
}
